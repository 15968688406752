import { Injectable } from '@angular/core';
import { remove } from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  private subject = new Subject<{ name: string, action: string }>();
  private spins: string[] = [];

  constructor(
    private spinner: NgxSpinnerService,
  ) {
    this.subject.subscribe(({ name, action }) => {
      if (action == "add") {
        this.spins.push(name);
        this.spinner.show();
      }
      else {
        remove(this.spins, (spin) => {
          return spin === name;
        });
        if (this.spins.length === 0) {
          this.spinner.hide();
        }
      }
    });
  }

  public add(name: string) {
    this.subject.next({ name: name, action: "add" });
  }

  public stop(name: string) {
    this.subject.next({ name: name, action: "remove" });
  }

}
